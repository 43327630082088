import React from 'react';
import PropTypes from 'prop-types'
import { Paper } from '@mui/material';
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  BarSeries,
} from '@devexpress/dx-react-chart-material-ui';
import { scaleBand } from '@devexpress/dx-chart-core';
import { ArgumentScale, Stack } from '@devexpress/dx-react-chart';
    
export default function DailyBarChart(props) {
    const { data, seriesinfo } = props;
    return (
        <Paper style={{width: '100%'}}>
            <Chart data={data}>
                <ArgumentScale factory={scaleBand} />

                <ArgumentAxis />
                <ValueAxis />
                {seriesinfo.map(info => <BarSeries key={info.key} {...info} />)}
                <Stack />
            </Chart>
        </Paper>
    )
}

DailyBarChart.propTypes = {
    data: PropTypes.array.isRequired,
    seriesinfo: PropTypes.array.isRequired
}