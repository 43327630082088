
export default class WebSocketHandler {
    constructor(url, accountId) {
        this.url = url;
        this.accountId = accountId;
        this.reconnectWait = 250
        this.handlers = []
        this.connect();
    }

    connect() {
        this.ws = new WebSocket(this.url);
        this.ws.onmessage = (event) => {
            this.handlers.forEach(h => {
                h(event)
            })
        }
        this.ws.onerror = (event) => {
            console.log("error")
            console.log(event)
        }
        this.ws.onopen = () => {
            this.reconnectWait = 250
        }
        this.ws.onclose = () => {
            console.log("WS Connection closed. Reconnecting in " + this.reconnectWait)
            if (this.shutdown) {
                return;
            }
            setTimeout(() => {
                this.connect();
            }, this.reconnectWait)
            this.reconnectWait += this.reconnectWait;
        }
    }

    addHandler(handler) {
        this.handlers.push(handler)
    }

    close() {
        this.shutdown = true
        this.ws.close()
    }
}
