import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontSize: 16
  },
  spacing: 4,
  palette: {
    mode: 'dark',
    primary: {
      main: '#556cd6',
      dark: '#3a3',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    // background: {
    //   default: '#fff',
    // },
    buy: {
      main: '#3a3',
      dark: '#3a3',
      contrastText: '#fff'
    },
    sell: {
      main: '#a33',
      dark: '#a33',
      contrastText: '#fff'
    }
  },
});

export default theme;
