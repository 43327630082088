/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types'
import { DataGrid } from '@mui/x-data-grid';
import ProfitLoss from './ProfitLoss';
import NumberFormat from 'react-number-format';
import {FilterList} from '@mui/icons-material';
import {IconButton} from '@mui/material';

import _ from 'lodash'

export default function PerformanceBySymbol(props) {
    const {trades, filter} = props;
    const [symbolFilter, setSymbolFilter] = filter;
    const gridData = _.flatten(
        _.values(
            _.mapValues(
                _.groupBy(trades, 'symbol'),
                arr => {
                    const winsArr = _.filter(arr, a => a.grossProfit > 0);
                    const lossArr = _.filter(arr, a => a.grossProfit < 0);
                    return {
                        id: arr[0].symbol,
                        symbol: arr[0].symbol,
                        wins: winsArr.length,
                        losses: lossArr.length,
                        profit: _.sumBy(winsArr, 'grossProfit'),
                        profitPips: _.sumBy(winsArr, t => t.direction === 'SHORT' ? -t.pips : t.pips),
                        loss: _.sumBy(lossArr, 'grossProfit'),
                        lossPips: _.sumBy(lossArr, t => t.direction === 'SHORT' ? -t.pips : t.pips),
                        percentProfitable: 100*winsArr.length/(winsArr.length+lossArr.length),
                        profitFactor: lossArr.length > 0 ? (
                            _.sumBy(winsArr, 'grossProfit') / Math.abs(_.sumBy(lossArr, 'grossProfit'))
                        ) : 100,
                        net: _.sumBy(arr, 'grossProfit')
                    }
                })
            ));
    const renderPL = params => <ProfitLoss value={params.value} />
    const renderSymbol = params => 
        <>
            {params.value}
            <IconButton 
                onClick={()=>setSymbolFilter(params.value === symbolFilter ? null : params.value)} 
                color={params.value === symbolFilter ? "secondary" : "default"}>
                    <FilterList/>
            </IconButton>
        </>
    const columns = [
        {
            field: 'symbol',
            flex: 1,
            minWidth: 100,
            headerName: 'Symbol',
            renderCell: renderSymbol
        },
        {
            field: 'wins',
            flex: 1,
            minWidth: 60,
            type: 'number',
            headerName: 'Wins',
            headerAlign: 'right',
        },
        {
            field: 'losses',
            flex: 1,
            minWidth: 60,
            type: 'number',
            align: 'right',
            headerName: 'Losses',
            headerAlign: 'right',
        },
        {
            field: 'profitFactor',
            flex: 1,
            minWidth: 100,
            type: 'number',
            align: 'right',
            headerName: 'Profit Factor',
            headerAlign: 'right',
            renderCell: params => {
                return <NumberFormat displayType='text' decimalScale={2} 
                    fixedDecimalScale={true} value={params.value} />
            }
        },
        {
            field: 'percentProfitable',
            flex: 1,
            minWidth: 100,
            type: 'number',
            align: 'right',
            headerName: '% Profitable',
            headerAlign: 'right',
            renderCell: params => {
                return <NumberFormat displayType='text' decimalScale={2} 
                    fixedDecimalScale={true} value={params.value} />
            }
        },
        {
            field: 'profit',
            flex: 1,
            minWidth: 100,
            headerName: 'Profit',
            headerAlign: 'right',
            align: 'right',
            renderCell: renderPL
        },
        {
            field: 'loss',
            flex: 1,
            minWidth: 100,
            headerName: 'Loss',
            headerAlign: 'right',
            align: 'right',
            renderCell: renderPL
        },
        {
            field: 'pips',
            flex: 1,
            minWidth: 100,
            headerName: 'Pips',
            headerAlign: 'right',
            align: 'right',
            valueGetter: params => {
                return parseFloat(params.getValue(params.id, 'profitPips')) 
                    + parseFloat(params.getValue(params.id, 'lossPips'));
            },
            renderCell: renderPL
        },
        {
            field: 'net',
            flex: 1,
            minWidth: 100,
            headerName: 'Net',
            headerAlign: 'right',
            align: 'right',
            renderCell: renderPL
        }
    ]

    return (
      <div style={{ width: '100%', display: 'flex', height: '400'}}>
        <div style={{ flexGrow: 1}}>
        {gridData && <DataGrid
          autoHeight
          rows={gridData}
          columns={columns}
          pagination 
          pageSize={10}/>}
        </div>
      </div>

    )
}

PerformanceBySymbol.propTypes = {
    filter: PropTypes.string.isRequired,
    trades: PropTypes.array.isRequired
}