import React from 'react';
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell,TableContainer, TableRow,
    Paper, Grid } from '@mui/material';
import NumberFormat from 'react-number-format';
import ProfitLoss from './ProfitLoss';

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
});

export default function TradeSummary(props) {
  const {summary} = props;
  const classes = useStyles();
  const firstTrade = summary && summary.trades && summary.trades[0] ? summary.trades[0] : {balance: summary && summary.currentBalance, grossProfit:0, commission:0}
  const startingBalance = parseFloat(firstTrade.balance - firstTrade.grossProfit - firstTrade.commission).toFixed(2);
  return summary && (
    <>
    <Grid container spacing={2} justifyContent="flex-start">
      <Grid item xs={12} md={3}>
      <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell>Starting Balance</TableCell>
            <TableCell align="right">
              <NumberFormat 
                displayType={'text'} thousandSeparator={true} 
                decimalScale={2} fixedDecimalScale={true}
                value={startingBalance}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Ending Balance</TableCell>
            <TableCell align="right">
              <NumberFormat 
                displayType={'text'} thousandSeparator={true} 
                decimalScale={2} fixedDecimalScale={true}
                value={parseFloat((summary && summary.trades && summary.trades[summary.trades.length-1] || firstTrade).balance).toFixed(2)}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Min Balance</TableCell>
            <TableCell align="right">
              <NumberFormat 
                displayType={'text'} thousandSeparator={true} 
                decimalScale={2} fixedDecimalScale={true}
                value={summary.minimumBalance < 1000000 && parseFloat(summary.minimumBalance).toFixed(2)}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Max Balance</TableCell>
            <TableCell align="right">
              <NumberFormat 
                displayType={'text'} thousandSeparator={true} 
                decimalScale={2} fixedDecimalScale={true}
                value={summary.maximumBalance > 0 && parseFloat(summary.maximumBalance).toFixed(2)}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Current Balance</TableCell>
            <TableCell align="right">
              <NumberFormat 
                displayType={'text'} thousandSeparator={true} 
                decimalScale={2} fixedDecimalScale={true}
                value={parseFloat(summary.currentBalance).toFixed(2)}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      </TableContainer>
      </Grid>
      <Grid item xs={12} md={3}>
      <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell>Total Profit</TableCell>
            <TableCell align="right"><ProfitLoss value={summary.totalGrossProfit} /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Profit</TableCell>
            <TableCell align="right"><ProfitLoss value={summary.profit} /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Loss</TableCell>
            <TableCell align="right"><ProfitLoss value={summary.loss} /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>% Gain</TableCell>
            <TableCell align="right"><ProfitLoss value={100*(summary.totalGrossProfit/startingBalance)} /></TableCell>
          </TableRow>
        </TableBody>
      </Table>
      </TableContainer>
      </Grid>
      <Grid item xs={12} md={3}>
      <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell>Profit Factor</TableCell>
            <TableCell align="right">
              <NumberFormat 
                displayType={'text'} thousandSeparator={true} 
                decimalScale={2} fixedDecimalScale={true}
                value={parseFloat(summary.profitFactor).toFixed(2)}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Percent Profitable</TableCell>
            <TableCell align="right">
              <NumberFormat 
                displayType={'text'} thousandSeparator={true} 
                decimalScale={2} fixedDecimalScale={true}
                value={parseFloat(summary.percentProfitable).toFixed(2)}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Average Trade Profit</TableCell>
            <TableCell align="right"><ProfitLoss value={summary.averageTradeNetProfit} /></TableCell>
          </TableRow>
        </TableBody>
      </Table>
      </TableContainer>
      </Grid>
      <Grid item xs={12} md={3}>
      <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell>Wins</TableCell>
            <TableCell align="right">{summary.wins}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Losses</TableCell>
            <TableCell align="right">{summary.losses}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      </TableContainer>
      </Grid>
      </Grid>
    </>
  ) || null;
}

TradeSummary.propTypes = {
  summary: PropTypes.object.isRequired
}