import React from 'react';
import { Container, Box } from '@mui/material';
import Dashboard from './Dashboard';

export default function App() {
  return (
    <Container maxWidth="xxl">
      <Box my={6}>
        <Dashboard/>
      </Box>
    </Container>
  );
}
