import React from 'react';
import PropTypes from 'prop-types'
import ProfitLoss from './ProfitLoss';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import _ from 'lodash';
// import { ArrowDropUpIcon, ArrowDropDownIcon } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const dateFormatter = (params) => {
    return moment(params.value).format('MMMDD HH:mm:ss');
}

export default function TradeList(props) {
  // eslint-disable-next-line react/prop-types
  const {trades,  symbolFilter} = props;
  const compact = false
  const columns = [
    {
        field: 'openTime',
        flex: 1,
        minWidth: 180,
        headerName: 'Open',
        hide: compact,
        valueFormatter: dateFormatter
    },
    {
        field: 'closeTime',
        flex: 1,
        minWidth: 180,
        headerName: 'Close',
        valueFormatter: dateFormatter
    },
    {
        field: 'symbol',
        flex: 0.9,
        minWidth: 100,
        headerName: 'Symbol',
        // valueGetter: () => 'foo',
        renderCell: (params) => {
          if (params.getValue(params.id, 'direction') === 'SHORT') {
            return <><ArrowDropDownIcon style={{color: 'ff0000'}}/> {params.value}</>
          } else {
            return <><ArrowDropUpIcon style={{color: '00ff00'}}/> {params.value}</>
          }
        }
    },
    // {
    //     field: 'entryPrice',
    //     flex: 1,
    //     minWidth: 120,
    //     headerName: 'Entry',
    //     align: 'right',
    //     hide: compact,
    //     renderCell: renderPrice
    // },
    // {
    //     field: 'exitPrice',
    //     flex: 1,
    //     minWidth: 120,
    //     headerName: 'Exit',
    //     align: 'right',
    //     hide: compact,
    //     renderCell: renderPrice
    // },
    {
        field: 'volume',
        hide: compact,
        flex: 0.75,
        minWidth: 100,
        headerName: 'Vol',
        headerAlign: 'right',
        align: 'right',
        type: 'number'
    },
    // {
    //     field: 'direction',
    //     flex: 0.5,
    //     // minWidth: 100,
    //     headerName: 'Dir'
    // },
    {
        field: 'pips',
        flex: 0.9,
        minWidth: 100,
        headerName: 'Pips',
        headerAlign: 'right',
        align: 'right',
        valueGetter: (params) => {
          if (params.getValue(params.id, 'direction') === 'SHORT') {
            return parseFloat(params.value) * -1.0;
          }
          return params.value;
        },
        renderCell: (params) => (
            <ProfitLoss value={params.value} />
        )
    },
    {
        field: 'grossProfit',
        flex: 1,
        hide: compact,
        headerName: 'Net P/L',
        headerAlign: 'right',
        align: 'right',
        minWidth: 100,
        renderCell: (params) => (
            <ProfitLoss value={params.value} />
        )
    }
  ];

  const data = _.filter((trades || []), (t) => !symbolFilter || t.symbol === symbolFilter).map(t => ({id: t.positionId + '-' + t.closeTimestamp, ...t}))
  return (
      <div style={{width: '100%', display: 'flex', height: '400'}}>
        {/* <div style={{ flexGrow: 1}}> */}
        {/* <div> */}
        {data && <DataGrid
          autoHeight
          initialState={{
            sorting: {
              sortModel: [{field: 'closeTime', sort: 'desc'}]
            }
          }}
          rows={data}
          columns={columns}
          pagination 
          pageSize={10}/>}
        {/* </div> */}
      </div>
  );
}

TradeList.propTypes = {
  trades: PropTypes.array.isRequired,
  symbolFilter: PropTypes.string.isRequired
}