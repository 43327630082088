import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles({
  profitloss: props => {
      const val = parseFloat(props.value);
      if (val < 0) {
          return ({color: "#f00", "font-weight": "bold"})
      } else if (val > 0) {
          return ({color: "#0a0", "font-weight": "bold"})
      }
  },
});

export default function ProfitLoss(props) {
    const classes = useStyles(props);
    return (
        <NumberFormat className={classes.profitloss} value={props.value === undefined ? null : props.value} 
            allowNegative={true} displayType={'text'} thousandSeparator={true} 
            decimalScale={2} fixedDecimalScale={true}/>
    )
}

ProfitLoss.propTypes = {
    value: PropTypes.number.isRequired,
}