import React from 'react';
import PropTypes from 'prop-types'
// import { Report, ArrowDropDown, ArrowDropUp} from '@mui/icons-material'
import moment from 'moment';
// import {
//   Typography
// } from '@mui/material'
// import { makeStyles } from '@mui/styles';
import PageVisibility from 'react-page-visibility';
import _ from 'lodash';
import retryOperation from './retry.js';
import Symbols from './Symbols.js';

// const useStyles = makeStyles((theme) => ({
//   chip: {
//     padding: theme.spacing(1)
//   },
//   button: {
//     margin: 5
//   },
//   grid: {
//     margin: 5,
//   },
//   tableContainer: {
//     background: '#257'
//   }
// }));

export default function Alerts(props) {
    const apiURLPrefix = process.env.REACT_APP_API_PREFIX || '/api'
    // const classes = useStyles();
    const [alerts, setAlerts] = React.useState([]);
    const {liveData, positionData, updatePosition, authorized, setStatusMessage, 
      name, riskAmount, riskPercentage, from, to, wsHandler, slackWebhookUrl} = props;
    const {selectedAccount} = props;

    const fetchAlerts = () => {
      const toParam = moment().format();
      retryOperation(() => fetch(`${apiURLPrefix}/alerts/?name=${name ? name : ''}&to=${toParam}`)
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          throw JSON.parse(response).error;
        }), 1000, 5)
        .then((alerts) => setAlerts(alerts));
    }

    React.useEffect(()=>{
        if (authorized) {
          fetchAlerts();
          // const interval = setInterval(() => fetchAlerts(), 5000);
          // return () => clearInterval(interval);
        }
    }, [selectedAccount, from, to, authorized]);

    const visibilityHandler = isVisible => {
        if (isVisible) {
            fetchAlerts();
        }
    }


    // if (alerts.length === 0) {
    //   // return <Accordion><AccordionSummary><Typography variant="body2">No alerts to show</Typography></AccordionSummary></Accordion>
    //   return <Typography variant="body2">No alerts to show</Typography>
    // }
    const alertData = _.groupBy(alerts, a => a.symbol);
    // const _render = 
    // <PageVisibility onChange={visibilityHandler}>
    // <Grid container justifyContent="flex-start" spacing={2} style={{marginBottom: 5}}>
    //   {_.toPairs(data).sort((a, b) => {
    //     return b[0] - a[0];
    //   }).map(pair => {
    //     const symbols = pair[1].sort((a, b) => a.symbol.localeCompare(b.symbol));
    //     return _.toPairs(_.groupBy(symbols, 'name')).map((nameSymbols) => {
    //       const [groupName, groupSymbols] = nameSymbols;
    //       const lastFire = moment(groupSymbols[0].lastFire);
    //       const events = groupSymbols[0].events;
    //       const timeframe = groupSymbols[0].timeframe;
    //       return <Grid item xs={12} md={4} key={lastFire}><Accordion>
    //         <AccordionSummary>
    //           <Grid container justifyContent="space-between" alignItems="center">
    //             <Grid item style={{padding: 5}}><Typography variant="body2">{lastFire.format('MMM DD HH:mm')}</Typography></Grid>
    //             <Grid item><Chip icon={events && events.length > 0 ? <Report /> : null} color="success" size="small" variant="outlined" label={groupName.replace('Strategy', ` ${timeframe ? timeframe : 'H1'}`)}/></Grid>
    //             <Grid item><Chip size="small" label={groupSymbols.length}/></Grid>
    //           </Grid>
    //         </AccordionSummary>
    //         <AccordionDetails>
    //         <Grid container>
    //           <List dense disablePadding> 
    //           {events && events.map(e => {
    //             var col = 'default';
    //             col = e.Importance === 'high' ? 'error' : col
    //             col = e.Importance === 'medium' ? 'warning' : col
    //             return <ListItem key={e.id}>
    //             <Chip icon={<Report />} color={col} size="small" variant="outlined" label={`${moment.utc(e.FullDate).local().format('HH:mm')} ${e.CurrencyCode} ${e.EventName}`} />
    //               {/* <ListItemIcon><Report /></ListItemIcon>
    //               <ListItemText>{`${moment.utc(e.FullDate).local().format('HH:mm')} ${e.CurrencyCode} ${e.EventName}`} </ListItemText> */}
    //             </ListItem>
    //           })}
    //           </List>
    //           {groupSymbols.map(a => {
    //             if (a.orderRequest) {
    //                 a.orderRequest.label = groupName;
    //                 const chipProps = {icon: a.orderRequest.direction === 'LONG' ? <ArrowDropUp style={{color: '#0f0'}} /> : <ArrowDropDown style={{color: '#f00'}} />, variant: 'outlined'}
    //                 const vol = parseFloat(a.orderRequest.volume)*riskAmount/a.orderRequest.risk/100
    //                 const stepVolume = a.orderRequest.stepVolume ? a.orderRequest.stepVolume/100.0 : vol > 1000 ? 1000.0 : 0.1
    //                 a.orderRequest.adjustedVolume = (vol/stepVolume).toFixed(0)*stepVolume * 100
    //                 return <Grid item key={a.symbol} className={classes.chip}>
    //                   <Chip
    //                     onClick={() => {executeOrder(a.symbol, a.orderRequest)}}
    //                     label={`${a.symbol} ${(vol > 1000 ? (vol/100000) : vol).toFixed(2)} / ${a.orderRequest.stopLoss}`}
    //                     {...chipProps} /></Grid>
    //             } else {
    //                 return <Grid item><Chip key={a.symbol} 
    //                     className={classes.chip}
    //                     label={`${a.symbol} ${a.name}`}
    //                     variant='outlined' /></Grid>
    //             }
    //           })}
    //           </Grid>
    //         </AccordionDetails>
    //       </Accordion></Grid>
    //     })
    //   })}
    // </Grid>
    // </PageVisibility>
    // return _render;
    return <PageVisibility onChange={visibilityHandler}>
      <Symbols alertData={alertData} wsHandler={wsHandler} setStatusMessage={setStatusMessage} 
        selectedAccount={selectedAccount} 
        updatePosition={updatePosition} 
        riskAmount={riskAmount}
        riskPercentage={riskPercentage}
        positionData={positionData}
        liveData={liveData}
        slackWebhookUrl={slackWebhookUrl}
      />
    </PageVisibility>
}

Alerts.propTypes = {
    selectedAccount: PropTypes.string.isRequired,
    setStatusMessage: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    riskAmount: PropTypes.number.isRequired,
    riskPercentage: PropTypes.number.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    authorized: PropTypes.bool.isRequired,
    liveData: PropTypes.bool.isRequired,
    updatePosition: PropTypes.object.isRequired,
    wsHandler: PropTypes.object.isRequired,
    positionData: PropTypes.object.isRequired,
    slackWebhookUrl: PropTypes.string.isRequired,
}