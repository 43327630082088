import React from 'react';
import PropTypes from 'prop-types'
import {Accordion, AccordionSummary, AccordionDetails, Box, Grid ,Chip, Button, Dialog, DialogTitle, Link} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { makeStyles } from '@mui/styles';
import PageVisibility from 'react-page-visibility';
import retryOperation from './retry.js';

const tradingViewUrl = 'https://www.tradingview.com/chart/ZNBW2oyn/?symbol=FX%3A';

const useStyles = makeStyles((theme) => ({
  chip: {
    padding: theme.spacing(1)
  },
  button: {
    margin: 5
  }
}));

export default function Positions(props) {
    const { liveData, setPositionData, positionChangeTime, wsHandler, 
        updatePosition, selectedAccount, refresh, setStatusMessage, setLoading} = props;
    const apiURLPrefix = process.env.REACT_APP_API_PREFIX || '/api'
    const chipClick = (symbol, positionId) => {
        setSymbol(symbol);
        setPositionId(positionId);
        setDialogOpen(true);
    }

    const visibilityHandler = isVisible => {
        if (isVisible && selectedAccount) {
            const [ provider, ] = selectedAccount.split(':');
            fetchPositions(provider);
        }
    }


    const spotHandler = (event) => {
        dispatch({type: 'update', data: JSON.parse(event.data)})
    }

    const fetchPositions = (provider) => {
        setLoading(true);
        return retryOperation(() => fetch(`${apiURLPrefix}/${provider}/positions`).then(response => {
            if (!response.ok) {
                throw JSON.parse(response.body).error
            }
            setLoading(false);
            return response.json()
        }), 1000, 5).then(positions => {
            dispatch({type: 'update', data: {type: 'PositionList', accountId: provider, positions: positions}})
        }).catch((error) => {
            // failure
            setStatusMessage(`Failed to fetch positions: ${error}`, 'error');
        });
    }

    React.useEffect(() => {
        if (wsHandler) {
            wsHandler.addHandler(spotHandler);
        }
    }, [wsHandler]);

    React.useEffect(() => {
        if (selectedAccount) {
            const [ provider, ] = selectedAccount.split(':');
            fetchPositions(provider);
        }
    }, [selectedAccount, positionChangeTime])

    const upgrade = (symbol, positionId, pct) => {
        const [provider] = selectedAccount.split(':');
        const pos = positionState.positions.find(p => p.positionId === positionId);
        fetch(`${apiURLPrefix}/${provider}/positions/${positionId}`, {
            method: 'PUT',
            body: JSON.stringify({volumeMultiplier: 1.0}),
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            if (response.status === 200) {
                // success
                setDialogOpen(false);
                setStatusMessage(`${symbol}:${positionId} position upgraded.`);
                fetchPositions(provider);
            } else {
                throw response.statusText;
            }
        }).catch((error) => {
            // failure
            setDialogOpen(false);
            setStatusMessage(`${symbol}:${positionId} Failed to upgrade: ${error}`, 'error');
        });
    }

    const lockIn = (symbol, positionId) => {
        const [provider] = selectedAccount.split(':');
        
        fetch(`${apiURLPrefix}/${provider}/positions/lockin/${positionId}`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            if (response.status === 200) {
                // success
                setDialogOpen(false);
                setStatusMessage(`${symbol}:${positionId} stop loss set and trailing stop enabled`);
                fetchPositions(provider);
            } else {
                throw response.statusText;
            }
        }).catch((error) => {
            // failure
            setDialogOpen(false);
            setStatusMessage(`${symbol}:${positionId} failed to lock in: ${error}`, 'error');
        });
    }

    const breakEven = (symbol, positionId) => {
        const [provider] = selectedAccount.split(':');
        
        fetch(`${apiURLPrefix}/${provider}/positions/breakeven/${positionId}`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            if (response.status === 200) {
                // success
                setDialogOpen(false);
                setStatusMessage(`${symbol}:${positionId} stop loss set to break even`);
                fetchPositions(provider);
            } else {
                throw response.statusText;
            }
        }).catch((error) => {
            // failure
            setDialogOpen(false);
            setStatusMessage(`${symbol}:${positionId} failed to set stop loss: ${error}`, 'error');
        });
    }

    const close = (symbol, positionId, pct) => {
        const [provider] = selectedAccount.split(':');
        
        fetch(`${apiURLPrefix}/${provider}/positions/${positionId}`, {
            method: 'DELETE',
            body: JSON.stringify(pct || 1),
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            if (response.status === 200) {
                // success
                setDialogOpen(false);
                setStatusMessage(`${symbol}:${positionId} position closed ${pct || ""}`);
                fetchPositions(provider);
                refresh();
            } else {
                throw response.statusText;
            }
        }).catch((error) => {
            // failure
            setDialogOpen(false);
            setStatusMessage(`${symbol}:${positionId} failed to close: ${error}`, 'error');
        });
    }

    const closeAll = () => {
        const [provider] = selectedAccount.split(':');
        fetch(`${apiURLPrefix}/${provider}/trades`, {
            method: 'DELETE',
        }).then((response) => {
            if (response.status === 200) {
                // success
                setStatusMessage('All positions closed');
                fetchPositions(provider)
                refresh();
            } else {
                throw response.statusText;
            }
        }).catch((error) => {
            // failure
            setStatusMessage(`Failed to close all positions: ${error}`, 'error');
        });
    }

    const reducer = (state, action) => {
        const [provider] = selectedAccount.split(':');
        let newState = state;
        switch (action.type) {
            case 'update':
                switch (action.data.type) {
                    case 'PositionList':
                        if (action.data.accountId === provider) {
                            const dataMap = {};
                            action.data.positions.forEach(p => { 
                                delete p.atrPercent;
                                delete p.pips;
                                dataMap[p.positionId] = p
                            });
                            const positionList = state.positions
                                .filter(p => dataMap[p.positionId] !== undefined)
                                .map(p => { 
                                    const newPos = {...p, ...dataMap[p.positionId]};
                                    delete dataMap[p.positionId];
                                    return newPos;
                                });
                            const newList = [ ...Object.values(dataMap), ...positionList, ];
                            setPositionData(newList);
                            return {positions: newList};
                        }
                        return state;
                    case 'TickData':
                        if (action.data.accountId === provider && state && state.positions) {
                            action.data.tickData.forEach(symbolData => {
                                newState = {positions: newState.positions.map(position => {
                                    if (position.symbol === symbolData.name) {
                                        var pips = (position.price - symbolData.ask)
                                        if (position.direction === 'LONG') {
                                            pips = (symbolData.bid - position.price) 
                                        }
                                        var atrPercent = (pips / symbolData.atr) * 100
                                        pips *= Math.pow(10, symbolData.pipPosition)
                                        return {...position, pips: pips, atrPercent: parseFloat(atrPercent)};
                                    }
                                    return position;
                                })}
                            });
                            return newState;
                        }
                        return state;
                    case 'PositionClosed':
                    case 'PositionUpdated':
                        updatePosition();
                        return state;
                    default:
                        return state;
                }
        }
    }
    const [positionState, dispatch] = React.useReducer(reducer, {positions: []})

    const [symbol, setSymbol] = React.useState();
    const [positionId, setPositionId] = React.useState();
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const classes = useStyles();
    return positionState && positionState.positions && (
        <PageVisibility onChange={visibilityHandler}>
            <Grid item xs={12}>
            <Dialog open={dialogOpen} onClose={()=>setDialogOpen(false)}>
                <DialogTitle>{symbol}</DialogTitle>
                <Button onClick={() => {upgrade(symbol, positionId, 1)}} className="button">Double</Button>
                {/* <Button onClick={() => {upgrade(symbol, 50)}} className="button">Upgrade 50</Button> */}
                <Button onClick={() => {close(symbol, positionId, 0.5)}} className="button">Close 1/2</Button>
                <Button onClick={() => {close(symbol, positionId)}} className="button">Close</Button>
                <Button onClick={() => {breakEven(symbol, positionId)}} className="button">Break Even</Button>
                <Button onClick={() => {lockIn(symbol, positionId)}} className="button">Lock In</Button>
                <Link onClick={() => {setDialogOpen(false); return true;}} component={Button} href={`${tradingViewUrl}${symbol}`} target="_blank" variant="body2">Chart</Link>
            </Dialog>
            <Accordion expanded={true}>
                <AccordionSummary>
                    <Grid container justifyContent="space-between" alignItems="flex-start" item xs={12}>
                        <Grid item>Positions</Grid>
                        <Grid item xs={6} md={4} container justifyContent="flex-end" spacing={3} alignItems="center">
                            <Grid item><Button variant="outlined" onClick={()=>closeAll()}>Close All</Button></Grid>
                            <Grid item><Chip label={positionState.positions.length} /></Grid>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Box>
                        <Grid container justifyContent="flex-start">
                        {positionState.positions.sort((a, b) => a.symbol.localeCompare(b.symbol)).map(p => {
                            const chipProps = {icon: p.direction === 'LONG' ? <ArrowDropUpIcon style={{color: '#0f0'}}/> : <ArrowDropDownIcon style={{color: '#f00'}}/>, variant: 'outlined'}
                            var background = '';
                            if (p.atrPercent > 0) {
                                background = 'rgb(64, 100, 255, 0.5)';
                            } 
                            if (p.atrPercent < -100) {
                                background = 'rgb(255, 128, 128, 0.5)';
                            } 
                            if (p.price === p.stopLoss) {
                                background = '#990';
                            }
                            if (p.trailingStopLoss || (p.direction === 'LONG' && p.price < p.stopLoss) || 
                                    (p.direction === 'SHORT' && p.price > p.stopLoss)) {
                                background = '#0A0';
                            } 
                            return <Grid item key={p.positionId} className={classes.chip}>
                                    <Chip onClick={() => {chipClick(p.symbol, p.positionId)}} 
                                        label={`${p.symbol} ${p.volume >= 1000 ? p.volume/1000+'K' : p.volume} ${ liveData ? `${p.pips ? p.pips.toFixed(1) : '--'} / ${p.atrPercent ? parseFloat(p.atrPercent).toFixed(1) : '--'}%` : ""}`} 
                                        {...chipProps} style={{background: background}} />
                                </Grid>
                        })}
                        </Grid>
                    </Box>
                </AccordionDetails>
            </Accordion>
            </Grid>
        </PageVisibility>
    ) || null;
}

Positions.propTypes = {
    setStatusMessage: PropTypes.object.isRequired,
    setLoading: PropTypes.object.isRequired,
    selectedAccount: PropTypes.string.isRequired,
    refresh: PropTypes.func.isRequired,
    wsHandler: PropTypes.object.isRequired,
    setPositionData: PropTypes.object.isRequired,
    updatePosition: PropTypes.object.isRequired,
    positionChangeTime: PropTypes.object.isRequired,
    liveData: PropTypes.bool.isRequired
}